import { createI18n } from 'vue-i18n';
import sr from './sr';

// Ready translated locale messages
const messages = {
  sr: sr,
};

const options = {
  locale: localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')).locale : 'sr', // set locale
  legacy: false,
  messages, // set locale messages
};

const i18n = createI18n(options);

export default i18n;

export const t = i18n.global.t;
export const te = i18n.global.te;
