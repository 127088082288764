import { defineStore } from 'pinia';
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';

export const useSettingsStore = defineStore(
  'settings',
  () => {
    const theme = ref('light');
    const locale = ref('sr');
    const autoLogout = ref(false);
    const sidebarCollapse = ref(true);

    const appConfig = inject('appConfig');
    const router = useRouter();

    const service = appConfig.env.service_config[appConfig.env.service_name];
    const registrationStartDate = DateTime.fromISO(service.registrationStartDate);
    const registrationEndDate = DateTime.fromISO(service.registrationEndDate);
    const validationStartDate = DateTime.fromISO(service.validationStartDate);

    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    if (theme.value === 'auto') {
      darkMediaQuery.addEventListener('change', () => {
        setTheme(darkMediaQuery.matches ? 'dark' : 'light');
      });
    }

    const setTheme = t => {
      theme.value = t;
      document.querySelector('body').className = '';
      document.querySelector('body').classList.add(t === 'auto' ? (darkMediaQuery.matches ? 'dark' : 'light') : t);
    };

    const setLocale = l => {
      locale.value = l;
      router.go(0);
    };

    const toggleSidebar = () => {
      sidebarCollapse.value = !sidebarCollapse.value;
    };

    const setAutoLogout = value => {
      autoLogout.value = value;
    };
    return {
      service,
      registrationStartDate,
      registrationEndDate,
      validationStartDate,
      theme,
      setTheme,
      locale,
      setLocale,
      sidebarCollapse,
      toggleSidebar,
      autoLogout,
      setAutoLogout,
    };
  },
  { persist: true },
);
