<script setup></script>
<template>
  <v-app-bar app flat height="132" class="pa-0 public-app-header header-print" color="pio-primary">
    <v-container fluid class="pa-0">
      <v-row no-gutters class="pb-3">
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-img height="100" src="@/assets/images/piologo.svg" style="flex-grow: unset" />
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
