import { computed } from 'vue';
import { defineStore } from 'pinia';
import { useCookies } from 'vue3-cookies';
import { ref } from 'vue';
import { clearState } from '@/constants/auth';
import { useSettingsStore } from './settings';

export const useAuthStore = defineStore(
  'authorization',
  () => {
    const { cookies } = useCookies();
    // IMPORTANT: Because of interaction between persistance and shared state plugins,
    // all of the refs need to be inside authState ref
    const authState = ref(JSON.parse(JSON.stringify(clearState)));
    const keepAlive = ref([]);
    const { setAutoLogout } = useSettingsStore();

    const setAuthState = value => {
      authState.value = Object.assign(authState.value, value);
    };
    const refreshActivity = () => {
      authState.value.lastActivity = Date.now();
    };

    const logout = redirect => {
      redirect && setAutoLogout(redirect);
      setAuthState(JSON.parse(JSON.stringify(clearState)));
      // remove cookies and other relevant items from localStorage
      cookies.remove('_accessToken');
      cookies.remove('_refreshToken');
      if (redirect) return;
      keepAlive.value = [];
    };

    const setKeepAlive = v => {
      keepAlive.value = v;
    };

    const isAdmin = computed(() => {
      if (!authState.value.user?.claims?.length) return false;
      return authState.value.user.claims.some(claim => ['a2', 'a1'].includes(claim));
    });

    return {
      authState,
      keepAlive,
      setAuthState,
      refreshActivity,
      logout,
      setKeepAlive,
      isAdmin,
    };
  },
  {
    persist: true,
    share: { omit: ['keepAlive'], enable: true, initialize: false },
  },
);
