export default {
  title: process.env.VUE_APP_TITLE,
  message: {
    login: 'Пријава',
    error: {
      default: 'Дошло је до грешке',
      login: 'Дошло је до грешке приликом пријављивања, молимо покушајте касније',
      wrongFileExtension: 'Молимо вас учитајте %accept фајл',
      unknown: 'Непозната грешка',
    },
  },
  header: {
    documentation: 'Документација',
    menu: {
      logout: 'Одјава',
    },
  },
  locale: {
    sr: 'Српски',
    en: 'Енглески',
  },
  login: {
    title: 'Пријава',
    forgottenPassword: 'Заборављена лозинка',
    loginAdAccount: 'Tрезор АД',
    loginStandardAccount: 'Пријава на сервис',
    anonymousAccess: 'Анонимни приступ',
  },
  navigation: {
    home: 'Почетна',
    registration: 'Подношење пријаве',
    validateAccount: 'Провера статуса пријаве',
  },
  validations: {
    messages: {
      required: '%field поље је неопходно',
      email: 'Имејл адреса није валидна.',
      numeric: '%field може да садржи само бројеве',
      between: '%field мора бити између %min и %max',
      maxLength: '%field може имати највише %max карактера',
      minLength: '%field мора имати најмање %min карактера',
      maxValue: '%field не може бити већи од %max',
      minValue: '%field не може бити мањи од %min',
      'cardReadyNotificationPhoneNumber-requiredIf': 'Контакт телефон је обавезно поље ако мејл адреса није унета',
      validMobilePhone: 'Контакт телефон мора имати позивни број српског оператера',
      'cardReadyNotificationPhoneNumber-validMobilePhone': 'Контакт телефон мора имати позивни број српског оператера',
      'cardReadyNotificationEmail-requiredIf': 'Мејл адреса је обавезно поље ако контакт телефон није унет',
      'advertisementNotificationAddress-requiredIf':
        'Адреса становања је обавезно поље ако број мобилног телефона и мејл адреса нису унети',
      'advertisementNotificationPhoneNumber-requiredIf':
        'Број мобилног телефона је обавезно поље ако адреса становања и мејл адреса нису унети',
      'advertisementNotificationEmail-requiredIf':
        'Мејл адреса је обавезно поље ако адреса становања и број мобилног телефона нису унети',
      'password-containsNumber': 'Лозинка мора садржати број',
      'password-containsSymbol': 'Лозинка мора садржати симбол',
      'password-containsCapitalLetter': 'Лозинка мора садржати велико слово',
      sameAsPassword: 'Лозинке нису исте',
    },
  },
  fields: {
    email: 'Е-пошта',
    password: 'Лозинка',
    confirmPassword: 'Потврди лозинку',
    uniqueIdentificationNumber: 'ЈМБГ',
    companyNumber: 'Матични број',
    cardReadyNotificationPhoneNumber: 'Контакт телефон',
    cardReadyNotificationEmail: 'Мејл адреса',
    advertisementNotificationAddress: 'Адреса становања',
    advertisementNotificationPhoneNumber: 'Број мобилног телефона',
    advertisementNotificationEmail: 'Мејл адреса',
    userName: 'Име и презиме',
    roleId: 'Улога',
    reissueReason: 'Разлог поновног издавања',
    rejectReason: 'Разлог гашења',
    activationComments: 'Разлог активације',
    deissueComments: 'Разлог укидања',
    description: 'Опис',
    firstName: 'Име',
    lastName: 'Презиме',
    userPasswordRequirement: {
      0: 'Лозинка мора садржати најмање 12 карактера',
      1: 'Лозинка мора садржати бројеве',
      2: 'Лозинка мора садржати мала и велика слова',
      3: 'Лозинка мора садржати симболе',
    },
  },
  beneficiary: {
    uniqueIdentificationNumber: 'ЈМБГ',
    registrationNumber: 'Регистарски број',
    beneficiaryNumber: 'Пензијски број',
    councilName: 'Општина',
    affiliate: 'Филијала',
    affiliateCode: 'Шифра филијале',
    affiliateName: 'Назив филијале',
    affiliateBranchCode: 'Шифра испоставе',
    balance: 'Салдо',
    cardNumber: 'Код картице',
    createdDate: 'Датум креирања',
    status: 'Статус',
    allotmentId: 'ЛОТ',
    allotmentContained: 'Садржан у ЛОТу',
    allotmentOverride: 'Дозволи кориснике из различитих филијала',
    allotmentOverrideHint: 'одабиром опције лот ће садржати кориснике из различитих филијала и експозитура',
    handoverDate: 'Датум преузимања',
    handoverName: 'Име и презиме лица које преузима картицу',
    identificationNumber: 'Број личне карте/пасоша лица које преузима картицу',
    identificationNumberIssuer: 'Издата од',
    cardReissuedComments: 'Разлог реиздавања',
    statuses: {
      1: 'Није пријављен',
      2: 'Пријављен',
      4: 'Угашен',
      5: 'За издавање картице',
      6: 'За ре-издавање картице',
      7: 'Картица издата',
    },
    statusText: {
      beneficiaryInactive: 'Корисник није поднео пријаву за пензионерску картицу',
      beneficiaryActive: 'Корисник је поднео пријаву за пензионерску картицу',
      beneficiaryRemoved: 'Корисник је искључен из сервиса за пензионерске картице',
      beneficiaryCardIssue: 'Корисник је поднео пријаву и чека на издавање пензионерске картице',
      beneficiaryCardReissue: 'Корисник је поднео пријаву за поновно издавање пензионерске картице',
      beneficiaryCardIssued: 'Кориснику је издата пензионерска картица',
    },
    requestTitle: 'Подаци пријаве',
    requestedDate: 'Датум подношења',
    cardIssuedUser: 'Картицу издао',
    cardIssuedDate: 'Датум издавања',
    rejectionDate: 'Датум одбијања',
    rejectionComments: 'Коментар',
    displayLatinNameOnCard: 'Желим да ми се Пензионерска картица изради на латиничком писму',
    allowCollectingData:
      'Пристајем да Фонд, у сврху остваривања одређених погодности за корисника Пензионерске картице кроз додељивање бодова, од Пореске управе, прикупи и обради моје податке са фискалног рачуна за плаћену робу и услуге, који настану у вези са коришћењем Пензионерске картице',
    allowCollectingDataCompliance:
      'Укидањем сагласности о прикупљању података, салдо пензионерске картице ће бити поништен и искључен из даљих трансакција.',
    advertisementNotification:
      'Пристајем да ми податке о коришћењу Пензионерске картице и обавештења о погодностима које могу добити коришћењем Пензионерске картице, Фонд доставља',
    load: {
      error: 'Поље "%field" нема исправан формат',
      errorTitle: 'У учитаном фајлу постоје следеће грешке',
      empty: 'Учитани фајл је празан',
      new: {
        success: 'Успешно учитани нови корисници',
      },
      deactivate: {
        success: 'Успешно деактивирани корисници',
      },
    },
    editResources: {
      FirstName: 'Име',
      LastName: 'Презиме',
      AffiliateCode: 'Шифра филијале',
      AffiliateName: 'Назив филијале',
      BeneficiaryNumber: 'Пензионерски број',
      RegistrationNumber: 'Регистрациони број',
      CouncilName: 'Општина',
      DisplayLatinNameOnCard: 'Картица на латиничном писму',
      CardReadyNotificationPhoneNumber: 'Контакт телефон',
      CardReadyNotificationEmail: 'Мејл адреса',
      AdvertisementNotification: 'Слање обавештења',
      AllowCollectingData: 'Сагласност о прикупљању податка',
      AdvertisementNotificationAddress: 'Адреса за промоције',
      AdvertisementNotificationPhoneNumber: 'Телефон за промоције',
      AdvertisementNotificationEmail: 'Мејл адреса за промоције',
    },
    editResourcesBoolean: {
      True: 'Да',
      False: 'Не',
    },
    selector: {
      add: {
        title: 'Упозорење',
        text: 'Да ли сте сигурни да желите да додате у селектор све кориснике који подлежу тренутном филтеру ?',
        input: 'Ограничи број елемената',
      },
      remove: {
        title: 'Упозорење',
        text: 'Да ли сте сигурни да желите да уклоните све кориснике из селектора ?',
      },
      filter: {
        label: 'Селектовани корисници',
      },
      invalid: 'Корисник већ додељен ЛОТ-у',
    },
    changeType: {
      1: 'Креиран',
      2: 'Ажуриран',
      3: 'Пријављен',
      4: 'Одбијен',
      5: 'За издавање картице',
      6: 'За ре-издавање картице',
      7: 'Картица издата',
      8: 'Укључен у ЛОТ',
      9: 'Поново активиран',
    },
    lot: {
      beneficiaries: 'Корисници у ЛОТ-у',
      fields: {
        id: 'ИД',
        description: 'Опис',
        size: 'Корисника у ЛОТу',
        affiliateCode: 'Филијала',
        affiliateBranchCode: 'Испостава',
        createdDate: 'Креиран',
        createdBy: 'Креирао',
        status: 'Статус',
      },
      statuses: {
        1: 'Креиран',
        2: 'Отказан',
        3: 'Послат на израду',
        4: 'Израђен',
      },
      create: {
        label: 'Креирај ЛОТ',
        labelShort: '+ ЛОТ',
        title: 'Креирај ЛОТ',
        text: 'Да ли сте сигурни да желите да креирате ЛОТ са %count корисника ?',
        success: 'ЛОТ креиран',
        tooltip: 'Могуће је крирати ЛОТ само са корисницима који имају статус %status',
      },
      cancel: {
        label: 'Откажи',
        title: 'Откажи ЛОТ',
        text: 'Да ли сте сигурни да желите да откажете ЛОТ?',
        success: 'ЛОТ отказан',
      },
      send: {
        label: 'Послат на израду ЗИНу',
        title: 'Означи да је ЛОТ послат',
        text: 'Да ли сте сигурни да желите да означите ЛОТ као послат на израду ЗИНу?',
        success: 'ЛОТ је успешно означен као послат на израду',
      },
      accept: {
        label: 'ЛОТ је израђен',
        title: 'Означи да је ЛОТ израђен',
        text: 'Да ли сте сигурни да желите да означите ЛОТ као израђен?',
        success: 'ЛОТ је успешно прихваћен као израђен',
      },
      issue: {
        label: 'ЛОТ је израђен и картице издате',
        title: 'Означи да је ЛОТ израђен и да су картице издате',
        text: 'Да ли сте сигурни да желите да означите да је ЛОТ израђен и све припадајуће картице издате?',
        success: 'ЛОТ је успешно прихваћен као израђен и картице из ЛОТа су означене као издате',
      },
      editResources: {
        DisplayLatinNameOnCard: 'Картица на латиничном писму',
        CardReadyNotificationPhoneNumber: 'Контакт телефон',
        CardReadyNotificationEmail: 'Мејл адреса',
        AdvertisementNotification: 'Слање обавештења',
        AllowCollectingData: 'Сагласност о прикупљању податка',
        AdvertisementNotificationAddress: 'Адреса за промоције',
        AdvertisementNotificationPhoneNumber: 'Телефон за промоције',
        AdvertisementNotificationEmail: 'Мејл адреса за промоције',
      },
    },
    card: {
      excludedComments: 'Картица је искључена из промета повлачењем сагласности о обради личних података',
      create: {
        label: 'Креирај картице',
        labelShort: '+ Картице',
        title: 'Креирај провизионе картице',
        text: 'Да ли сте сигурни да желите да креирате провизионе картице за %count корисника ?',
        success: 'Провизионе картице креиране',
        tooltip: 'Могуће је крирати провизионе картице само за кориснике који имају статус %status',
      },
      statuses: {
        1: 'Активна',
        2: 'Неактивна',
        3: 'Угашена',
        4: 'Искључена из промета',
      },
    },
    tabs: {
      general: 'Подаци',
      history: 'Промене',
      card: 'Картица',
      beneficiaries: 'Корисници',
    },
    export: {
      firstName: 'Ime',
      lastName: 'Prezime',
      uniqueIdentificationNumber: 'JMBG',
      registrationNumber: 'Registarski_Broj',
      beneficiaryNumber: 'Penzijski_Broj',
      councilName: 'Opstina',
      affiliateCode: 'Sifra_Filijale',
      affiliateName: 'Naziv_Filijale',
      cardNumber: 'Broj_Kartice',
      // Saldo_Kartice (ukoliko postoji)
    },
    history: {
      changeDate: 'Датум промене',
      changeUser: 'Променио',
      status: 'Статус',
      resources: 'Поља',
    },
    reissue: {
      label: 'Захтев за издавање нове картице',
      title: 'Издавање нове картице',
      success: 'Послат захтев за издавање нове картице',
    },
    issue: {
      label: 'Преузимање картице',
      title: 'Преузимање картице',
      text: 'Да ли сте сигурни да желите да потврдите преузимање картице?',
      success: 'Картица преузета од стране корисника',
    },
    reactivate: {
      label: 'Реактивација корисника',
      title: 'Реактивација корисника',
      text: 'Да ли сте сигурни да желите да активирате угашеног корисника?',
      success: 'Угашени корисник је успешно активиран',
    },
    deissue: {
      label: 'Укидање реиздавања',
      title: 'Укидање реиздавања',
      text: 'Да ли сте сигурни да желите да укинете захтев за реиздавање картице?',
      success: 'Захтев за реиздавање је успешно укинут',
    },
    reject: {
      label: 'Гашење корисника',
      title: 'Гашење корисника',
      text: 'Да ли сте сигурни да желите да угасите корисника?',
      success: 'Корисник РФПИО је успешно угашен',
    },
    edit: {
      label: 'Ажурирај',
      nameLabel: 'Ажурирај име',
      success: 'Корисник ажуриран',
    },
    report: {
      beneficiaryData: {
        text: 'Да ли сте сигурни да желите да извезете све податке о корисницима и картицама',
        title: 'Извоз корисника',
      },
      cumulative: {
        statsText: 'Да ли сте сигурни да желите да генеришете кумулативну статистику?',
        statsTitle: 'Кумулативна статистика',
      },
    },
  },
  company: {
    registerTitle: 'Регистрација компаније',
    registerDescription: 'Подношење захтева за приступ сервису за правно лице',
    compliance:
      'Захтев подноси искључиво правно лице како би остварило машински (комерцијални) приступ функционалностима сервиса',
    registration: {
      companyNumber: 'Матични број компаније',
      name: 'Назив компаније',
      contactPhone: 'Контакт телефон',
      contactEmail: 'Електронска пошта',
      query: 'Претрага',
      queryText: 'Унесите матични број компаније и одаберите дугме Претрага како би систем пронашао пословне податке',
      confirmation: 'Потврда подношења захтева',
      confirmationSuccess: 'Успешно сте поднели пријаву за правно лице',
      confirmationDescription:
        'Након провере податка упутство за приступ сервису ће бити послато на емаил адресу правног лица',
      emailMissing: 'Подаци АПР за наведено правно лице немају дефинисану неопходну емаил адресу',
      submissionDate: 'Захтев поднет',
      accessGrantedDate: 'Приступ одобрен',
      accessRevokedDate: 'Приступ укинут',
      accessRevokedUser: 'Укинуо корисник',
      dispatchAttempts: 'Број покушаних слања',
      dispatchMessage: 'Грешка приликом слања',
      status: {
        1: 'поднета пријава',
        2: 'неуспела активација',
        3: 'приступ омогућен',
        4: 'укинут приступ',
      },
    },
    revokeAccess: {
      label: 'Укидање приступа',
      title: 'Укидање приступа',
      text: 'Након укидања приступа правном лицу, компанија губи могућност провере статуса картица',
      success: 'Успешно сте укинули приступ правном лицу',
    },
  },
  account: {
    registerAccount: 'Подношење пријаве',
    validateAccount: 'Провера статуса пријаве',
    reportIssue: 'Пријава проблема',
    uniqueIdentificationNumber: 'ЈМБГ',
    firstName: 'Име',
    lastName: 'Презиме',
    bank: 'Банка',
    identificationNumber: 'Број личне карте поднет приликом регистрације',
    identificationNumberShort: 'Број личне карте',
    accountNumber: 'Број рачуна',
    description: 'Опис проблема (макс. 250 карактера)',
    continue: 'Напред',
    registerAccountSuccess: 'Успешно сте пријавили број рачуна',
    registerAccountTitle: 'Подношење пријаве за пензионерску картицу',
    validateAccountTitle: 'Провера статуса пријаве',
    registerAccountSubTitle: 'Подношење пријаве за пензионерску картицу',
    registerAccountSubTitleShort: 'Подношење пријаве за пензионерску картицу',
    validateAccountSubTitle: 'Провера статуса пријаве за пензионерску картицу',
    validateAccountSubTitleShort: 'Провера статуса пријаве за пензионерску картицу',
    reportIssueTitle: 'Пријава корисничких проблема у вези са пензионерском картицом',
    reportIssueTitleShort: 'Пријава корисничких проблема у раду са сервисом',
    validateAccountSuccess: 'Пријављени рачун за исплату новчане помоћи је валидан',
    reportIssueSubText: 'Или позивом на број Контакт центра',
  },
  organization: {
    id: 'Број захтева',
    budgetUserId: 'ЈБКЈС',
    companyNumber: 'Матични број',
    companyNumberShort: 'МБ',
    taxIdNumber: 'ПИБ',
    name: 'Назив правног лица/органа',
    address: 'Адреса седишта',
    treasuryBranch: 'Број организационе јединице',
    status: 'Статус организације',
    statuses: {
      1: 'У току',
      2: 'Одобрена',
      3: 'Отказана',
    },
    details: {
      legalEntityInfo: 'Подаци о правном лицу/органу',
      canceledUserName: 'Регистрацију отказао',
      canceledDate: 'Датум отказивања',
      cancelRegistrationPrompt: 'Да ли сте сигурни да желите да откажете регистрацију?',
      createdDate: 'Датум регистрације',
      approvedDate: 'Датум активације',
      activationHeading: 'Активација администраторског налогa',
      approveRegistration: 'Активирај налог',
      approveRegistrationMsg: 'Организација успешно активирана',
      cancelRegistration: 'Откажи налог',
    },
  },
  user: {
    login: 'Пријава',
    username: 'Адреса електронске поште',
    password: 'Лозинка',
    confirmPassword: 'Потврди лозинку',
    email: 'И-мејл',
    firstTimeLogin: 'Пријављујем се први пут',
    passwordForgotten: 'Заборављена лозинка',
    passwordResetLinkSent: 'Линк за поновно постављање лозинке је послат на вашу адресу електронске поште',
    phoneNumber: 'Број телефона',
    mobileNumber: 'Број мобилног телефона',
    role: 'Корисничка улога',
    status: 'Статус',
    statuses: {
      1: 'Активан',
      2: 'Неактиван',
      3: 'Деактивиран',
    },
    create: {
      label: 'Креирај корисника',
      title: 'Креирање корисника',
      success: 'Корисник креиран',
    },
    edit: {
      label: 'Измена налога',
      title: 'Ажурирај податке корисника',
      success: 'Подаци корисника ажурирани',
    },
    deactivate: {
      label: 'Опозив налога',
      success: 'Корисник деактивиран',
    },
    activate: {
      label: 'Активирај',
      success: 'Корисник активиран',
    },
  },
  dashboard: {
    text: 'Добродошли',
  },
  issue: {
    id: 'Редни број',
    phoneNumber: 'Број телефона',
    mobileNumber: 'Број мобилног телефона',
    subsidyRegisterConfirmation: 'Проверите да ли сте унели исправне податке.',
    reportIssueSuccess: 'Успешно сте пријавили проблем.',
    email: 'Електронска пошта',
    name: 'Име',
    description: 'Опис',
    uniqueIdentificationNumber: 'ЈМБГ',
    userType: 'Тип корисника',
    identificationNumber: 'Број личне карте',
    createdDate: 'Датум креирања',
    lastChangeDate: 'Датум последње измене',
    lastChangedUserId: 'Последњи изменио',
    changedComments: 'Коментар',
    comment: 'Коментар',
    status: 'Статус',
    review: 'Преглед',
    details: 'Детаљи',
    resolveComment: 'Коментар',
    unresolveComment: 'Коментар',
    denyComment: 'Коментар',
    resolve: 'Означи као решено',
    resolveTitle: 'Означи као решено',
    denyTitle: 'Означи као одбијено',
    unresolve: 'Означи као нерешено',
    unresolveTitle: 'Означи као нерешено',
    deny: 'Означи као одбијено',
    changeStatusSuccess: 'Успешно сте променили статус проблема',
    problemType: 'Тип проблема',
  },
  issueStatus: {
    1: 'У току',
    2: 'Решено',
    3: 'Нерешено',
    4: 'Одбијено',
  },
  sidebar: {
    dashboard: 'Почетни екран',
    administration: 'Администрација',
    manageSystemUsers: 'Системски корисници',
    manageCompanies: 'Партнерске компаније',
    registerSystemUsers: 'Масовно креирање корисника',
    registerSystemUser: 'Креирање корисника',
    reviewUsers: 'Пријаве за исплату новчане помоћи',
    registerUserSubsidy: 'Претрага пријава за исплату новчане помоћи',
    registerUser: 'Регистрација пријаве за исплату новчане помоћи',
    updateUser: 'Претрага валидних пријава',
    reviewUser: 'Претрага свих пријава',
    reviewOrganizations: 'Правна лица',
    subsidy: 'Новчана помоћ',
    payments: 'Плаћања',
    generateOrders: 'Генерисање налога',
    reports: 'Извештаји',
    subsidyReport: 'Субвенције грађана',
    ministryReport: 'Дневни извештај за МФ',
    issues: 'Рекламације',
    issueReport: 'Рекламације',
    beneficiaries: 'Корисници РФПИО',
    beneficiariesList: 'Преглед података',
    beneficiariesLoad: 'Учитавање података',
    beneficiariesAllotment: 'ЛОТ',
    beneficiariesAllotmentDetails: 'Детаљи',
    interventions: 'Интервенције',
    interventionSelector: 'Селектор',
    interventionDeissue: 'Укидање реиздавања',
    groupIssue: 'Групно издавање',
    groupReissue: 'Групно реиздавање',
  },
  interventions: {
    selector: {
      registerError: 'Грешке приликом креирања селектора',
      registerSuccess: 'Успешно сте креирали ставке селектора',
    },
    issue: {
      registerError: 'Грешке приликом збирног издавања картица',
      registerSuccess: 'Поднели сте захтев за издавање одабраних картица - проверите појединачни списак грешака',
    },
    reissue: {
      registerError: 'Грешке приликом збирног реиздавања картица',
      registerSuccess: 'Поднели сте захтев за реиздавање одабраних картица - проверите појединачни списак грешака',
    },
  },
  reports: {
    filter: {
      from: 'Од',
      to: 'До',
      userType: 'Тип корисника',
      bank: 'Банка',
    },
    excel: 'Ексел',
    ministry: 'Министарство',
    issue: 'Рекламације-кумулативно',
  },
  contextPath: {
    administration: 'Администрација',
    organizations: 'Организације',
    users: 'Корисници',
    subsidy: 'Пријава за исплату новчане помоћи',
    systemusers: 'Системски корисници',
    partner: 'Партнери',
    user: 'Физичка лица',
    organization: 'Правна лица',
    accounts: 'Рачуни',
    reviewaccount: 'Рачуни',
    reports: 'Извештаји',
    payments: 'Плаћања',
    issue: 'Рекламације',
    details: 'Детаљи',
  },
  tabulator: {
    ajax: {
      loading: 'Учитавам', // ajax loader text
      error: 'Грешка', // ajax error text
    },
    groups: {
      // copy for the auto generated item count in group header
      item: 'ставка', // the singular  for item
      items: 'ставке', // the plural for items
    },
    pagination: {
      page_size: 'Редова по страни:', // label for the page size select element
      first: 'Прва', // text for the first page button
      first_title: 'Прва страна', // tooltip text for the first page button
      last: 'Последња',
      last_title: 'Последња страна',
      prev: 'Претходна',
      prev_title: 'Претходна страна',
      next: 'Следећа',
      next_title: 'Следећа страна',
      counter: {
        showing: 'Приказано',
        of: 'од',
        rows: 'редова',
        pages: 'страна',
      },
    },
    headerFilters: {
      default: 'филтрирај', // default header filter placeholder text
      columns: {
        name: 'филтрирај име', // replace default header filter text for column name
      },
      gte: 'Од',
      lte: 'До',
    },
    emptyTable: 'Нема информација',
    totalElements: 'Укупно редова: ',
    rowTooltip: '[Средњи клик миша] отварање детаља на новој картици',
    newTabTooltip: 'Отварање детаља на новој картици',
  },
  roles: {
    Role_Administrator: 'Администратор',
    Role_Control: 'Контрола',
    Role_Operator: 'Оператер управе за трезор',
    Role_HelpDesk: 'Корисничка подршка',
    Role_Integration: 'Интеграција',
    Role_Refresh_Token: 'Освеживање токена',
    1: 'Администратор',
    2: 'Оператер РФПИО',
    3: 'Шалтерски радник РФПИО',
    4: 'ЦРМ Интеграција',
    5: 'ПУРС Интеграција',
  },
  claims: {
    label: 'Oвлашћења',
    name: 'Назив',
    description: 'Опис',
    Claim_Admin: 'Администрација',
    Claim_Admin_Description: 'Управљање свим функционалностима на систему',
    Claim_SystemUser_Create: 'Креирање корисника',
    Claim_SystemUser_Create_Description: 'Креирање глобалног корисника на систему',
    Claim_SystemUser_Review: 'Преглед корисника',
    Claim_SystemUser_Review_Description: 'Преглед корисника на систему',
    Claim_SystemUser_Revoke: 'Опозив корисника',
    Claim_SystemUser_Revoke_Description: 'Опозив корисника од стране оператора трезора',
  },
  general: {
    firstName: 'Име',
    lastName: 'Презиме',
    phone: 'Број телефона',
    email: 'Адреса електронске поште',
    changelog: 'Информације о верзијама сервиса',
    technicalSupport: 'Пријава проблема',
    nameAndSurname: 'Име и презиме',
    personalIdentityNumber: 'ЈМБГ',
    identityNumber: 'Број идентификационе исправе',
    postalCode: 'Поштански број',
    noDataAvailable: 'Подаци нису доступни',
    userLogin: 'Кориснички налог',
    clearAll: 'Обриши све',
    export: {
      title: 'Извоз',
      beneficiary: 'Корисници',
      beneficiaryData: 'Картице',
    },
    cumulativeExport: 'Статистика',
    new: 'Нови корисници',
    deactivate: 'Укидање корисника',
    row: 'Ред',
    details: 'Детаљи',
  },
  misc: {
    clear: 'Очисти',
  },
  bool: {
    yes: 'Да',
    no: 'Не',
  },
  status: {
    Active: 'Активан',
    Inactive: 'Неактиван',
    Revoked: 'Опозван',
    Disabled: 'Онемогућен',
  },
  form: {
    ok: 'У реду',
    cancel: 'Одустани',
    submit: 'Потврди',
    submitRequest: 'Поднеси пријаву',
    validateRequest: 'Провери пријаву',
    send: 'Пошаљи',
    close: 'Затвори',
    save: 'Сачувај',
    apply: 'Примени',
    edit: 'Измени',
    warning: 'Упозорење',
    notification: 'Обавештење',
    load: 'Учитај',
    saveToFile: 'Сачувај у фајл',
    saveToDiagnosticFile: 'Сачувај у дијагностички фајл',
    saveToClipboard: 'Сачувај у клипборд',
    capsLockOn: 'Укључен је CapsLock',
    activate: 'Активирај',
    revoke: 'Опозови',
    saveSubscription: 'Сачувај претплату',
    add: 'Унос',
    update: 'Ажурирај',
    success: 'Успешно сте извршили акцију',
    delete: 'Обриши',
    exportToCsv: 'Извоз у цсв',
    exportToJson: 'Извоз у јсон',
    notChangedForm: 'Морате променити бар једно поље',
    separatorType: 'CSV сепаратор',
    hideGeneratedProperties: 'Не извози системски генерисане атрибуте',
    back: 'Назад',
  },
  activation: {
    title: 'Активација корисника',
    success: 'Корисник успешно активиран',
    activationSent: 'Линк за активацију корисника је послат на вашу адресу електронске поште',
  },
  reset: {
    title: 'Постављање лозинке',
    success: 'Корисничка лозинка успешно постављена',
  },
  errors: {
    someErrorsOccuredCheckListBelow: 'Дошло је до грешке. Проверите списак грешака испод',
    fileNameLength: 'Назив документа улазног фајла није исправан',
    token: {
      refreshTokenExpired: 'Обновљиви токен је истекао',
      failedToRefreshToken: 'Грешка приликом добијања приступног токена након освежавања',
    },
  },
  autocomplete: {
    completeSedarchMessage:
      "Унети критеријум '{query}' задовоља више од {limit} резултата. Сузите критеријум или наставите претрагу на {url}",
    urlText: 'spisku KJS',
  },
  dropzone: {
    defaultMessage: 'Превуците фајл у уоквирени део или кликните за дијалог',
    allowedFormats: 'Дозвољени фајлови типа: ',
    unsupportedFile: 'Недозвољен тип фајла! Дозвољени типови фајла су: %extension',
    tooManyFiles: 'Превукли сте више од дозвољеног броја фајлова',
    loadedFile: 'Учитан фајл',
  },
  systemUsers: {
    loadErrorTitle: 'Списак грешака при креирању системских корисника',
    importSystemUsersEmptyData: 'Датотека са системским корисницима није пронађена',
    importSystemUsersSuccess: 'Системски корисници успешно учитани',
    validationImport: 'Креирање није могуће док се грешке не исправе',
    revokeDate: 'Датум',
    revokeLogin: 'Променио',
    revokeComment: 'Коментар опозива',
    roles: 'Корисничке улоге',
    roleDescription: 'Опис корисничке улоге',
    success: 'Успешно сте променили информације корисника',
    statusRestore: 'Успешно сте опозвали корисника',
    statusActivate: 'Успешно сте активирали корисника',
    lastChangeDate: 'Датум промене',
  },
  administration: {
    reviewSystemUsers: 'Преглед системских корисника',
    userDetails: 'Детаљи корисничког налога',
    organizationDetails: 'Детаљи правног лица/органа',
    userStatus: 'Статус корисника',
    email: 'Електронска пошта',
    name: 'Име',
    administration: 'Управљање корисницима',
    localAdministration: 'Управљање локалним корисницима',
    localUserRoles: 'Корисничкa улогa',
    localUserClaims: 'Корисничка овлашћења',
    groupName: 'Назив групе',
    userGroups: 'Група корисника',
    roleName: 'Назив улоге',
    roleDescription: 'Опис',
    addNewUser: 'Нови Корисник',
    addNewGroup: 'Нова Група',
    addNewRole: 'Нова Улога',
    apply: 'Примени',
    userCreateConfirmation: 'Креирати новог локалног корисника?',
    userGroupCreateConfirmation: 'Да ли желите да креирате нову корисничку групу?',
    userRoleCreateConfirmation: 'Да ли желите да креирате нову корисничку улогу?',
    createUserSuccessful: 'Успешно сте креирали корисника',
    createRoleSuccessful: 'Успешно сте креирали корисничку улогу',
    createGroupSuccessful: 'Успешно сте креирали групу',
    profileEditPrompt: 'Да ли сте сигурни да желите да измените информације корисника?',
    permissionEditPrompt: 'Да ли сте сигурни да желите да измените овлашћења и улоге корисника?',
    revokePromptTitle: 'Да ли сте сигурни да желите да опозовете корисника?',
    revokePromptPlaceholder: 'Унесите коментар опозива',
    activatePrompt: 'Да ли сте сигурни да желите да активирате корисника?',
    userPasswordRequirement: {
      0: 'лозинка мора садржати најмање 12 карактера',
      1: 'лозинка мора садржати бројеве',
      2: 'лозинка мора садржати велика слова',
      3: 'лозинка мора садржати симболе',
    },
    changePassword: 'Промена лозинке',
    changePersonalInformation: 'Промена личних података',
    oldPassword: 'Стара лозинка',
    newPassword: 'Нова лозинка',
    confirmNewPassword: 'Потврди нову лозинку',
    password: 'Лозинка',
    confirmPassword: 'Потврди лозинку',
    confirmPasswordFail: 'Нова лозинка се не подудара са потврдом лозинке',
    passwordChanged: 'Нова лозинка успешно постављена',
    createUserPrompt: 'Да ли сте сигурни да желите да додате корисника?',
    profile: 'Профил',
    InfoNotSet: 'Нисте унели податке о кориснику',
    userInformationUpdateSuccess: 'Успешно сте изменили информације корисника.',
    userWithoutAvailableRoles: 'Корисник нема дефинисане расположиве улоге',
    roleCreated: 'Датум креирања',
    roleCreatedBy: 'Креирао',
    groupCreated: 'Датум креирања',
    groupCreatedBy: 'Групу креирао',
    groupStatus: 'Статус групе',
    importUsers: 'Масовно креирање корисника',
  },
  subsidy: {
    reviewUsers: 'Преглед пријава за исплату новчане помоћи',
    reviewUser: 'Преглед пријаве за исплату новчане помоћи',
    reviewOrganizations: 'Преглед правних лица',
    reviewOrganization: 'Преглед правног лица',
    companyNumber: 'Матични број',
    accountNumber: 'Број рачуна',
    taxIdNumber: 'ПИБ',
    companyName: 'Назив',
    companyStatus: 'Статус',
    userUniqueIdentificationNumber: 'ЈМБГ',
    userAccountNumber: 'Број рачуна',
    userIdentificationNumber: 'Број личне карте',
    userFirstName: 'Име',
    userLastName: 'Презиме',
    userStatus: 'Статус',
    subsidyHeadline:
      '<strong style="color: #7d98b7;">МОЈА</strong> ДРЖАВА <strong style="color: #7d98b7;">МЕ</strong>ПОНОВО ПОДРЖАВА',
    subsidySubheadline:
      '<strong style="color: #7d98b7;">30<span>&#8364;</span></strong> ОПЕТ, А <strong style="color: #7d98b7;">20<span>&#8364;</span></strong> ОДМАХ ЗАТИМ',
    generateOrders: 'Генерисање налога за плаћање',
  },
  subsidyUser: {
    firstName: 'Име',
    lastName: 'Презиме',
    uniqueIdentificationNumber: 'ЈМБГ',
    identificationNumber: 'Број личне карте',
    accountNumber: 'Број рачуна',
    address: 'Адреса',
    city: 'Град',
    bank: 'Банка',
    createdDate: 'Датум креирања захтева',
    userType: 'Тип корисника',
    createdComments: 'Коментар регистрације',
    bankHint: 'Банка у којој ће бити отворен наменски рачун уколико је неопходно',
    subsidyAmount: 'Износ новчане помоћи',
    receivedAmount: 'Исплаћен износ',
    rejectUser: 'Искључи пријаву за исплату новчане помоћи',
    registerUser: 'Региструј пријаву за исплату новчане помоћи',
    userRegistration: 'Регистрација пријаве за исплату новчане помоћи',
    activeUserReview: 'Претрага валидне пријаве',
    userReview: 'Претрага свих пријава',
    registerUserSubsidy: 'Исплата новчане помоћи',
    rejectionComments: 'Разлог искључивања пријаве из система',
    paymentAmount: 'Износ за уплату',
    dateOfBirth: 'Датум рођења',
    subsidyPaymentDate: 'Датум плаћања',
    subsidyFailed:
      'Ваша пријава је одбијена услед неисправности унетих података. Молимо да поново поднесете пријаву са исправним подацима.',
    subsidyActive: 'Пријава није поднета. Пријаву можете поднети на страни',
    subsidyRequested: ' Ваша пријава је успешно поднета',
    subsidyDelivered: 'Ваша пензионерска картица је испоручена',
    subsidyRejected: 'Искључени сте из система',
    subsidyFailedOperator: 'Пријава је одбијена услед неисправности унетих података.',
    subsidyPendingOperator: 'Подаци о поднетој пријави су у процесу обраде.',
    subsidyActiveOperator: 'Пријава је успешно прихваћена и очекује даљу обраду.',
    subsidyWaitingAccountOperator:
      'У току је повезивање личних података са текућим рачуном у банци која је одабрана приликом пријаве.',
    subsidyAwaitingPaymentOperator: 'Пријава је повезана са текућим рачуном у банци. Очекује се слање у платни промет.',
    subsidyProcessedOperator: 'Пријава је послата на плаћање и очекује се уплата на рачун.',
    subsidyProcessedFailedOperator: 'Уплата средстава на наведени текући рачун није успела',
    subsidyCompletedOperator: 'Новчана помоћ је уплаћена на рачун у одабраној банци',
    subsidyRejectedOperator: 'Пријава је искључена из система за пензионерску картицу',
    subsidyErrorOperator: 'Грешка приликом исплате новчане помоћи',
    subsidyUserStatusUpdated: 'Статус пријаве за исплату новчане помоћи је успешно измењен',
    subsidySubmit: 'Потврди плаћање',
    subsidySubmitSuccess: 'Успешно сте извршили плаћање новчане помоћи',
    subsidyUserActivationSuccess: 'Хвала, успешно сте поднели пријаву за пензионерску картицу.',
    subsidyRejectedReason: 'разлог искључивања',
    activationDate: 'Време прихватања',
    activationComments: 'Идентификатор интервенције техничке подршке',
    subsidyRegisterConfirmation: 'Проверите да ли сте унели исправне податке',
    subsidyActivationId: 'Запишите број пријаве како бисте остварили могућност провере стања',
    activationId: 'Идентификатор пријаве',
    activationIdHint: 'Број од осам (8) цифара који сте добили након успешно поднете пријаве',
    identificationNumberHint: 'Уколико ваша лична карта има осам (8) цифара, молимо да унесете водећу нулу',
    subsidyValidationSuccess: 'Статус пријаве за пензионерску картицу',
    currencyEur: 'ЕУР',
    currencyDin: 'ДИН',
    agreementHeader:
      'Уредбом о формирању привременог регистра и начину уплате​​ једнократне новчане помоћи свим пунолетним држављанима Републике Србије у циљу смањивања негативних ефеката проузрокованих пандемијом болести Ковид-19 предвиђено је да привремени регистар, између осталог, садржи податке о пунолетним држављанима Републике Србије на дан ступања на снагу  ове уредбе, а на основу појединачне пријаве лица која имају активно пребивалиште на територији Републике Србије. Уредба је објављена у "Службеном гласнику РС", бр. 60/2020 од 24.4.2020.​​ године, када је и ступила на снагу и представља правни основ за обраду податка од стране Министарства финансија. Ови подаци се чувају и штите у складу са одредбама закона којим се уређује заштита података о личности.',
    agreementText:
      'У складу са чланом 15. Закона о заштити података о личности („Службени гласник РС", број 87/18) саопштавњем Ваших података неопходних за доделу једнократне новчане помоћи приликом пријаве дајете свој пристанак да се Ваши подаци о личности, који обухватају ЈМБГ и број личне карте обрађују у сврху исплате једнократне новчане помоћи, истовремено потврђујући да су подаци које сте унели тачни и ажурни. Лице на које се подаци односе не сноси трошкове обраде и обавештења.',
    agreementFooter: 'Након реализације свих исплата, подаци садржани у привременом регистру ће бити обрисани',
    agreementConfirmation: 'Потврђујем сагласност',
    serviceTermination:
      'Пријава за новчану помоћ је престала 15.05.2021 у 24 часа. Провера статуса пријаве као и пријава рекламација у вези са исплатом новчане помоћи је омогућена до 15.06.2021.',
    requestedOrders: 'Захтевано налога',
    availableOrders: 'Доступно налога',
    requestOrder: 'Генериши налоге',
    requestOrderError: 'Грешка приликом слања налога, проверите бројеве доступних налога у табели',
    groupOrder: 'Групни налог',
    orderNumber: 'Редни број налога',
    downloadGroupFile: 'Преузми XML фајл',
    downloadSingleFile: 'Преузми CSV фајл',
    paymentBatch: 'Послато на плаћање',
    ordersRetrieve: 'Преузми доступно',
    problemType: 'Тип проблема',
    registrationRejectionReason: 'Разлог одбијања пријаве',
    rejectionReason: {
      0: 'Неисправни лични подаци',
      1: 'Одбио МУП',
      2: 'Одбио МДУЛС',
      12: 'Одбио МУП и МДУЛС',
      3: 'Није вакцинисан',
    },
  },
  validation: {
    nameRequired: 'Неопходно је унети име',
    userTypesRequired: 'Неопходно је унети име',
    emailRequired: 'Неопходно је унети електронску пошту',
    roleRequired: 'Неопходно је изабрати улогу',
    filterUrlRequired: 'Неопходно је унети филтер URL',
    oldPasswordNecessary: 'Неопходно је унети стару лозинку',
    newPasswordNecessary: 'Неопходно је унети стару лозинку',
    repeatedPasswordNecessary: 'Неопходно је унети стару лозинку',
    repeatedPasswordNotMatch: 'Неопходно је да се нова и поновљена лозинка подударају.',
    repeatMustMatch: 'Лозинке се не поклапају',
    oldPasswordRequired: 'Неопходно je унети стару лозинку',
    passwordRequired: 'Неопходно je унети лозинку',
    password: {
      0: 'лозинка мора бити најмање дужине 12',
      1: 'лозинка мора садржати бројеве',
      2: 'лозинка мора садржати велика слова',
      3: 'лозинка мора садржати симболе',
    },
    emailFormat: 'Е-маил није у одговарајућем формату',
    duplicateEmail: 'Е-маил је дуплиран у листи',
    userNameFormat: 'Име није у одговарајућем формату',
    userNameEmpty: 'Име није дефинисано',
    emailEmpty: 'Е-маил није дефинисан',
  },
  userStatuses: {
    1: 'Неактиван',
    2: 'Активан',
    3: 'Чека на рачун',
    4: 'У обради',
    5: 'Исплаћен',
    6: 'Одбијен',
    7: 'Грешка',
  },
  userTypes: {
    1: 'Запослено лице',
    2: 'Незапослено лице',
    3: 'Пензионер',
    4: 'Пензионер са готовинском исплатом',
    5: 'Социјална категорија',
    6: 'Остали корисници',
  },
  exportUserTypes: {
    1: 'Пензионери',
    2: 'Корисници социјалне помоћи',
    3: 'УИКС',
    4: 'Остали',
    5: 'Кумулативно',
  },
  issueUserTypes: {
    1: 'Корисници социјалне помоћи',
    2: 'Остали',
  },
  problemTypes: {
    1: 'Није ми уплаћен новац у банци у којој примам пензију',
    2: 'На мом рачуну примамо пензије моја деца и ја, а примили смо само једну уплату',
    3: 'Не живим на територији Републике Србије али примам пензију из ове земље',
    4: 'Нисам у могућности да подигнем свој новац, да ли може неко уместо мене',
    5: 'Новац се не налази на рачуну у банци која је наведена приликом провере статуса',
    6: 'Више пута сам се пријавио са подацима из важећих докумената, а на провери статуса не испуњавам услове',
    7: 'Исправност мојих података је потврђена од стране релевантног органа, а и даље не испуњавам услов',
    8: 'Остало',
  },
  userSubsidyTypes: {
    1: 'Пензионери и примаоци социјалне помоћи',
    2: 'Остали',
  },
  systemUserStatuses: {
    1: 'Активан',
    2: 'Неактиван',
    3: 'Деактивиран',
  },
  groupStatuses: {
    1: 'Активна',
    2: 'Неактивна',
  },
  rowStatus: {
    active: 'Активан',
    inactive: 'Неактиван',
  },
  apiStatusCodes: {
    0: 'Подаци о физичким лицима су у статусу обраде, молимо покушајте поново након пар минута',
    400: 'Обрада захтева за исплату новчане помоћи није успела, молимо покушајте поново након пар минута',
    401: 'Грешка приликом ауторизације корисника',
    403: 'Немате одговарајуће корисничке привилегије',
    404: 'Није пронађена захтевана страна',
    422: 'Формат послатог захтева није исправан',
    429: 'Лимит на број упућених захтева за појединачаног корисника је достигнут. Уколико захтев подносите са радног места или преко дељене интернет адресев, молимо покушајте поново након неколико минута.',
    500: 'Подаци о физичким лицима су у статусу обраде, молимо покушајте поново након неколико минута',
    VALIDATION_ERROR: 'Формат послатог захтева није исправан',
    // Fluent
    UNIQUE_IDENTIFICATION_NUMBER: 'ЈМБГ',
    IDENTIFICATION_NUMBER: 'Број личне карте',
    USER_ACCOUNT_NUMBER: 'Број рачуна',
    USER_PAYMENT_AMOUNT: 'Износ новчане помоћи',
    FIRST_NAME: 'Име',
    LAST_NAME: 'Презиме',
    USER_REJECTION_COMMENTS: 'Коментар искључења',
    USER_ACTIVATION_COMMENTS: 'Коментар активације',
    USER_ACTIVATION_ID: 'Идентификатор пријаве',
    SUBSIDY_REPORT_FILTER: 'Филтер',
    CARD_READY_NOTIFICATION_PHONE_NUMBER: 'Контакт телефон',
    CARD_READY_NOTIFICATION_EMAIL: 'Емаил адреса',
    AUTHORITY_FIRST_NAME: 'Име',
    AUTHORITY_LAST_NAME: 'Презиме',
    AUTHORITY_ADDRESS: 'Улица и број',
    AUTHORITY_PLACE: 'Место',
    AUTHORITY_POSTAL_CODE_AND_MUNICIPALITY: 'Поштански број и општина',
    AUTHORITY_PHONE_NUMBER: 'Контакт телефон',
    ADVERTISEMENT_NOTIFICATION_ADDRESS: 'Адреса становања',
    ADVERTISEMENT_NOTIFICATION_PHONE_NUMBER: 'Број мобилног телефона',
    ADVERTISEMENT_NOTIFICATION_EMAIL: 'Електронска адреса',
    INVALID_BENEFICIARY_UNIQUE_IDENTIFICATION_NUMBER_VALIDATION: 'ЈМБГ није валидан',
    INVALID_BENEFICIARY_FIRST_NAME_VALIDATION: 'Име није валидно',
    INVALID_BENEFICIARY_LAST_NAME_VALIDATION: 'Презиме није валидно',
    INVALID_BENEFICIARY_CREATED_COMMENTS_VALIDATION: 'Коментар није валидан',
    INVALID_BENEFICIARY_STATUS_VALIDATION: 'Статус није валидан',
    INVALID_BENEFICIARY_TYPE_VALIDATION: 'Тип није валидан',
    INVALID_BENEFICIARY_REJECTION_COMMENTS_VALIDATION: 'Коментар није валидан',
    INVALID_BENEFICIARY_AFFILIATE_CODE_VALIDATION: 'Шифра филијале није валидна',
    INVALID_BENEFICIARY_AFFILIATE_NAME_VALIDATION: 'Назив филијале није валидан',
    INVALID_BENEFICIARY_BENEFICIARY_NUMBER_VALIDATION: 'Пензијски број није валидан',
    INVALID_BENEFICIARY_REGISTRATION_NUMBER_VALIDATION: 'Регистарски број није валидан',
    INVALID_BENEFICIARY_COUNCIL_NAME_VALIDATION: 'Назив општине није валидан',
    INVALID_BENEFICIARY_CARD_READY_NOTIFICATION_EMAIL_VALIDATION: 'Мејл адреса није валидна',
    INVALID_BENEFICIARY_CARD_READY_NOTIFICATION_PHONE_NUMBER_VALIDATION: 'Број телефона није валидан',
    EMPTY_BENEFICIARY_CARD_READY_NOTIFICATION_VALIDATION: 'Мејл адреса или број телефона морају бити унети',
    INVALID_BENEFICIARY_ADVERTISEMENT_NOTIFICATION_EMAIL_VALIDATION: 'Мејл адреса није валидна ',
    INVALID_BENEFICIARY_ADVERTISEMENT_NOTIFICATION_PHONE_NUMBER_VALIDATION: 'Број телефона није валидан',
    INVALID_BENEFICIARY_ADVERTISEMENT_NOTIFICATION_ADDRESS_VALIDATION: 'Адреса није валидна',
    EMPTY_BENEFICIARY_ADVERTISEMENT_NOTIFICATION_VALIDATION: 'Мејл адреса, број телефона или адреса морају бити унети',
    MISSING_BENEFICIARY_UPDATE: 'Послати захтев не садржи измене текућих података',
    INVALID_REISSUE_PERIOD: 'Није дозвољено реиздавање картице у периоду краћем од 1 дан након издавања картице',
    MISSING_BENEFICIARY_CARDS: 'Пензионерске картице не постоје за датог корисника',

    RETRIEVE_COMPANY_REGISTRY: 'Правно лице није пронађено на основу унетог матичног броја',
    REGISTERED_AUTHORIZED_COMPANY: 'Правно лице је већ поднело захтев',
    REGISTER_AUTHORIZED_COMPANY: 'Подошење захтева није успело',

    SYSTEM_USER_LOGIN: 'Адреса електронске поште',
    SYSTEM_USER_NAME: 'Корисничко име',
    SYSTEM_USER_PASSWORD: 'Лозинка',
    SYSTEM_USER_NEW_PASSWORD: 'Нова лозинка',
    SYSTEM_USER_ROLE_ID: 'Корисничка улога',
    SYSTEM_USER_ID: 'Ид системског корисника',
    SYSTEM_USER_CHANGED_COMMENTS: 'Коментар',

    ISSUE_NAME: 'Име и презиме',
    ISSUE_EMAIL: 'Електронска пошта',
    ISSUE_UNIQUE_IDENTIFICATION_NUMBER: 'ЈМБГ',
    ISSUE_USER_TYPE: 'Тип корисника',
    ISSUE_PROBLEM_TYPE: 'Тип проблема',
    ISSUE_DESCRIPTION: 'Опис',
    ISSUE_IDENTIFICATION_NUMBER: 'Број личне карте',
    ISSUE_OTHER_CONTACT: 'Други контакт',

    USER_TYPE: 'Неисправан формат типа корисника',
    // Beneficiary

    RETRIEVE_BENEFICIARY: 'Корисник РФПИО није пронађен',
    RETRIEVE_PAGED_BENEFICIARIES: 'Није могуће прикупити податаке о корисницима РФПИО',
    CREATE_BENEFICIARY: 'Креирање нових корисника РФПИО није успешно',
    DEACTIVATE_BENEFICIARY: 'Деактивација корисника РФПИО није успела',
    RETRIEVE_BENEFICIARIES: 'Грешка приликом прикупљања података о корисницима РФПИО',
    ALREADY_CREATED_BENEFICIARY: 'Корисник РФПИО са наведеним подацима је већ креиран у систему',
    ALREADY_REGISTERED_BENEFICIARY_CARD_REQUEST: 'Пријава за пензионерску картицу је већ регистрована у систему',
    DUPLICATE_BENEFICIARY_CREATION: 'Регистрован је дупли захтев за креирање корисника РФПИО',
    DUPLICATE_BENEFICIARY_UPDATE: 'Регистрован је дупли захтев за ажурирање корисника РФПИО',
    DUPLICATE_BENEFICIARY_DEACTIVATION: 'Регистрован је дупли захтев за деактивацију корисника РФПИО',
    INVALID_BENEFICIARY_CREATE_STATE: 'Корисник РФПИО није креиран',
    INVALID_BENEFICIARY_UPDATE_STATE: 'Корисник РФПИО није ажуриран',
    INVALID_BENEFICIARY_DEACTIVATE_STATE: 'Корисник РФПИО није деактивиран',
    REJECTED_BENEFICIARY_STATUS: 'Корисник РФПИО је већ деактивиран',
    VALIDATE_BENEFICIARY_CARD_REQUEST_STATUS:
      'Поднета пријава за пензионерску картицу није пронађена за унети ЈМБГ, молимо проверите унете податке',
    REGISTER_BENEFICIARY_CARD_REQUEST: 'Није могуће регистровати захтев за пензионерску картицу',
    INVALID_CARD_NUMBER: 'Код картице није у одговарајућем формату',
    MISSING_CARD: 'Није пронађена картица са наведеним бројем',
    INVALID_CARD_DEBIT: 'Није могуће ажурирати бодове на картици',
    DISPATCH_ALLOTMENT: 'Није могуће слање ЛОТ-а',
    CANCEL_ALLOTMENT: 'Није могуће отказивање ЛОТ-а',
    CREATE_ALLOTMENT: 'Није могуће крерање ЛОТ-а',
    ACCEPT_ALLOTMENT: 'Није могуће прихватање ЛОТ-а',
    INVALID_ALLOTMENT: 'ЛОТ са наведеним идентификатором није пронађен',
    INVALID_CANCEL_ALLOTMENT_STATUS: 'ЛОТ не може бити отказан ако није у статусу - креиран',
    INVALID_DISPATCH_ALLOTMENT_STATUS: 'ЛОТ не може бити послат ако није у статусу - креиран',
    INVALID_ACCEPT_ALLOTMENT_STATUS: 'ЛОТ не може бити прихваћен ако није у статусу - послат',
    RETRIEVE_ALLOTMENT_USERS: 'Није могуће прикупити податаке о корисницима РФПИО ЛОТ-а',
    REMOVE_ALLOTMENT_ID: 'Уклањање ЛОТ идентификатора није успело',
    SET_ALLOTMENT_ID: 'Упис ЛОТ идентификатора није успео',
    EMPTY_BENEFICIARY_SELECTOR: 'Селектор је празан и нема изабране кориснике РФПИО',
    RETRIEVE_BENEFICIARY_SELECTOR_USERS: 'Није могуће прикупити податке о корисницима РФПИО у селектору',
    INVALID_BENEFICIARIES_SELECTOR_USERS_STATUS_FOR_ALLOTMENT:
      'Одређени корисници РФПИО у селектору нису у одговарајућем статусу за креирање ЛОТ-а',
    INVALID_BENEFICIARIES_SELECTOR_USERS_STATUS_FOR_CARD:
      'Одређени корисници РФПИО у селектору нису у одговарајућем статусу за креирање картице.',
    REMOVE_ALL_BENEFICIARY_SELECTOR: 'Није могуће испразнити селектор',
    INVALID_STATE_BENEFICIARY_SELECTOR: 'Није могуће ажурирати селектор',
    CREATE_BENEFICIARY_CARD: 'Није могуће креирати картице',
    CARD_CREDIT_REGISTRATION_FAILED: 'Није могуће ажурирати потрошњу на картици',
    CARD_ACTIVATE: 'Статуса корисника РФПИО није одговарајућ за издавање картице',
    SET_BENEFICIARY_CARD: 'Није могуће ажурирање статуса и картице корисника РФПИО',
    INVALID_STATE_BENEFICIARY_CARD_CREATE: 'Креирање картице није успело',
    INVALID_RE_REGISTER_CARD_STATUS: 'Корисник РФПИО није у одговарајућем статусу за реиздавање картице',
    DEACTIVATE_BENEFICIARY_CARD: 'Није могуће деактивирати постојећу картицу',
    FORBIDDEN_AFFILATE_MIXIN: 'Није дозвољено укључивање корисника из различитих филијала у исти ЛОТ',
    FORBIDDEN_AFFILATE_BRANCH_MIXIN: 'Није дозвољено укључивање корисника из различитих испостава филијале у исти ЛОТ',
    ALLOTTED_SELECTOR_BENEFICIARY: 'Неки од корисника у селектору су већ укључени у други ЛОТ',
    MISSING_BENEFICIARY: 'Корисник није пронађен',
    BENEFICIARY_CARD_MISSING: 'Пензионерска картица није пронађена',
    INVALID_DEISSUE_USER_STATUS: 'Статус корисника није исправан за укидање захтева за реиздавање',
    INVALID_BENEFICIARY_CARD_DEISSUE_STATUS: 'Статус картице корисника није исправан за укидање захтева за реиздавање',
    DEISSUE_BENEFICIARY_CARD: 'Укидање захтева за реиздавање није успело',

    ENDED_ANONYMOUS_USER_REGISTRATION: 'Пријава за пензионерску картицу је престала',

    DB_PAGED_BENEFICIARIES: 'Грешка приликом прикупљања података о корисницима РФПИО',
    DB_RETRIEVE_BENEFICIARIES: 'Грешка приликом прикупљања података о корисницима РФПИО',
    DB_RETRIEVE_BENEFICIARY: 'Грешка приликом прикупљања података о кориснику РФПИО',
    DB_CREATE_BENEFICIARIES: 'Грешка приликом креирања корисника РФПИО',
    DB_UPDATE_BENEFICIARIES: 'Грешка приликом ажурирања корисника РФПИО',
    DB_DEACTIVATE_BENEFICIARIES: 'Грешка приликом деактивације корисника РФПИО',
    DB_VALIDATE_BENEFICIARY_CARD_REQUEST_STATUS: 'Грешка приликом провера статуса захтева за пензионерску картицу',
    DB_REGISTER_BENEFICIARY_CARD_REQUEST: 'Грешка приликом регистровања захтева за пензионерску картицу',
    DB_CARD_BALANCE: 'Грешка приликом прикупљања података о стању на картици',
    DB_REGISTER_CARD_DEBIT: 'Грешка приликом ажурирања бодова на картици',
    DB_REGISTER_CARD_CREDIT: 'Грешка приликом ажурирања потрошње на картици',
    DB_CREATE_ALLOTMENT: 'Грешка приликом креирања ЛОТ-а',
    DB_DISPATCH_ALLOTMENT: 'Грешка приликом слања ЛОТ-а',
    DB_CANCEL_ALLOTMENT: 'Грешка приликом отказивања ЛОТ-а',
    DB_ACCEPT_ALLOTMENT: 'Грешка приликом прохватања ЛОТ-а',
    DB_ALLOTMENT_USERS: 'Грешка приликом прикупљања података о ЛОТ корисницима РФПИО',
    DB_RETRIEVE_ALLOTMENT: 'Грешка приликом прикупљања података о ЛОТ-у',
    DB_REMOVE_ALL_BENEFICIARY_SELECTOR: 'Грешка приликом пражњења селектора',
    DB_SET_BENEFICIARY_SELECTOR: 'Грешка приликом ажурирања селектора',
    DB_CREATE_BENEFICIARY_CARD: 'Грешка приликом креирања картица',
    DB_ACTIVATE_BENEFICIARY_CARD: 'Грешка приликом ажурирања статуса картице',
    DB_RE_CREATE_BENEFICIARY_CARD: 'Грешка приликом реиздавања картице',
    DB_DEISSUE_BENEFICIARY_CARD: 'Грешка приликом укидања захтева за реиздавање картице',
    TRAN_INVALID_CARD_DEBIT: 'Грешка приликом ажурирања потрошње на картици',

    // Administration
    USER_NOT_FOUND: 'Кориснички налог није пронађен',
    PAGED_SYSTEM_USERS: 'Грешка приликом прикупљања података о системским корисницима',
    INVALID_PASSWORD: 'Кориснички креденцијали нису исправни',
    USER_INACTIVE: 'Корисник није активан, молимо обавите процедуру Пријављујем се први пут',
    TOKEN_REVOKED: 'Токен системског корисника није валидан или је деактивиран',
    REFRESH_TOKEN: 'Помоћни токен за системског корисника није пронађен',
    PASSWORD_ERROR: 'Грешка приликом промене лозинке',
    INVALID_CLAIMS: 'Није могуће прикупити информације о корисничким привилегијама',
    SYSTEM_USER_INACTIVE: 'Системски корисник је означен као неактиван',
    SYSTEM_USER_DISABLED: 'Системски корисник је означен као деактивиран',
    CREATE_SYSTEM_USER: 'Грешка приликом креирања системског корисника. Подаци нису валидни',
    UPDATE_SYSTEM_USER: 'Грешка приликом измене системског корисника. Подаци нису валидни',
    RETRIEVE_ROLES: 'Грешка приликом прикупљања података о корисничким улогама',
    ACTIVATION_TOKEN_INVALID: 'Активациони токен није валидан или је празан',
    RETRIEVE_REGISTRATION: 'Kориснички налог није пронађен',
    SYSTEM_USER_ALREADY_ACTIVATED: 'Системски корисник је већ активан',
    SYSTEM_USER_DEACTIVATED: 'Системски корисник је деактивиран',
    ACTIVATION_MAIL_CLIENT: 'Грешка приликом слања активационог емаил-а',
    PASSWORD_RESET_MAIL_CLIENT: 'Грешка приликом слања емаил-а за промену лозинке',
    RETRIEVE_ACTIVATION_TOKEN: 'Грешка приликом прикупљања валидног активационог токена',
    RETRIEVE_ACTIVE_SYSTEM_USER: 'Грешка приликом прикупљања активног корисничког налога',
    ACTIVATE_SYSTEM_USER: 'Грешка приликом активирања корисникичког налога',
    ERR_FAILED_TO_SELF_ASSIGN_STATUS: 'Промена својих података није дозвољена',
    ERR_FORBIDDEN_SYSTEM_USER_STATUS_UPDATE: 'Покушај промене недозвољеног статуса системског корисника',
    ERR_FAILED_TO_PERSIST_SYSTEM_USER_STATUS: 'Грешка приликом промене статуса системског корисника',
    PASSWORD_LENGTH: 'Лозинка мора бити најмање дужине 12',
    PASSWORD_NUMBERS: 'Лозинка мора садржати бројеве',
    PASSWORD_UPPERCASE: 'Лозинка мора садржати велика слова',
    PASSWORD_SYMBOLS: 'Лозинка мора садржати симболе',
    CHANGE_ROLE_NOT_VALID: 'Промена системске улоге није дозвољена',
    SYSTEM_USER_LOGIN_DUPLICATE: 'Пронађене дупле адресе електронске поште',
    SYSTEM_USER_LIMIT: 'Прекорачен број дозвољених системских корисника',

    DB_ACCESS_TOKEN: 'Непозната грешка приликом креирања приступног токен',
    DB_REFRESH_TOKEN: 'Непозната грешка приликом генерисања помоћног токена',
    DB_RETRIEVE_SYSTEM_USERS: 'Непозната грешка приликом добијања информација о системским корисницима',
    DB_CREATE_SYSTEM_USER: 'Непозната грешка приликом креирања системских корисника',
    DB_CLAIMS_RETRIEVE: 'Непозната грешка приликом добијања информација о корисничкин привелегијама',
    DB_SYSTEM_USER_RETRIEVE: 'Непозната грешка приликом добијања информација о системском кориснику',
    DB_VALIDATE_SYSTEM_USER: 'Непозната грешка приликом валидације системског корисника',
    DB_ACTIVATION_TOKEN_CREATE: 'Непозната грешка приликом креирања активационог токена',
    DB_ACTIVATE_REGISTERED_SYSTEM_USER: 'Непозната грешка приликом активације регистрације системског корисника',
    DB_VALIDATE_ACTIVATION_TOKEN: 'Непозната грешка приликом валидације активационог корисника',
    DB_PASSWORD_CHANGE: 'Непозната грешка приликом промене лозинке системског корисника',
    DB_UPDATE_SYSTEM_USER: 'Непозната грешка приликом измене системског корисника',

    TRAN_CREATE_SYSTEM_USER_LOGIN_EXISTS: 'Кориснички налог заузет',
    TRAN_CREATE_SYSTEM_USER: 'Непозната грешка приликом креирања системских корисника',
    TRAN_UNKNOWN: 'Непозната грешка прикиком извршавања команди над базом података',
  },
};
