import { createApp, markRaw } from 'vue';
import pinia from '@/plugins/pinia';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import PortalVue from 'portal-vue';
import './assets/style/style.scss';
import 'tabulator-tables/dist/css/tabulator_semanticui.min.css';

const app = createApp(App);

import config from '@/configuration';
app.provide('appConfig', config);

import { globalCookiesConfig } from 'vue3-cookies';
globalCookiesConfig({
  secure: window.location.protocol === 'https:',
});

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

app.use(i18n);
app.use(pinia);
app.use(router);
app.use(vuetify);
app.use(PortalVue);

app.mount('#app');
