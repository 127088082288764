<script setup>
import { nextTick } from 'vue';
import { useDisplay } from 'vuetify';
import { useAuthStore } from '@/stores/authorization';
import { useSettingsStore } from '@/stores/settings';
import { useLoaderStore } from '@/stores/loader';
import { storeToRefs } from 'pinia';
const { authState, logout } = useAuthStore();

const { xs } = useDisplay();
const { toggleSidebar } = useSettingsStore();
const { sidebarCollapse } = storeToRefs(useSettingsStore());

const onLogout = () => {
  // logout call has to be inside next tick or vuetify menu will error
  nextTick(() => {
    logout();
  });
};
const { loading } = storeToRefs(useLoaderStore());
</script>

<template>
  <v-app-bar elevation="2" color="appbar">
    <router-link v-if="!xs" to="/">
      <v-toolbar-items class="ml-3">
        <img height="48" :src="require('@/assets/images/piologo.svg')" />
      </v-toolbar-items>
    </router-link>
    <template v-if="xs">
      <v-progress-circular v-if="loading" indeterminate class="mx-4" />
      <v-app-bar-nav-icon v-else variant="text" @click.stop="toggleSidebar" />
    </template>
    <template v-else>
      <v-progress-circular v-if="loading" indeterminate class="mx-4" />
      <div v-else style="width: 32px" class="mx-4" />
      <div v-if="!sidebarCollapse" style="width: 120px" />
    </template>

    <portal-target name="actions"></portal-target>

    <v-spacer />

    <v-toolbar-items class="mr-2">
      <v-btn text dark class="body-2 text-none" target="_blank" prepend-icon="mdi-help-circle">
        {{ $t('header.documentation') }}
      </v-btn>
    </v-toolbar-items>
    <v-divider vertical inset class="mr-4"></v-divider>
    <v-menu>
      <template #activator="{ props }">
        <v-btn icon v-bind="props">
          <v-avatar class="text-h5" size="38" color="rgb(242, 242, 242)">
            <span>
              {{ authState.user.login.charAt(0).toUpperCase() }}
            </span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item value="user" :title="authState.user.login" disabled />
        <v-divider />
        <v-list-item value="logout" @click="onLogout">
          <template #append>
            <v-icon icon="mdi-logout" left></v-icon>
          </template>

          <v-list-item-title v-text="$t('header.menu.logout')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
