import { t } from '@/plugins/i18n';
const helpUrls = require('@/configuration/help.json');

export default [
  {
    path: '/dashboard',
    component: () => import('@/views/private/dashboard/DashboardView.vue'),
    name: 'dashboard',
    meta: {
      title: t('navigation.home'),
      helpUrl: helpUrls.dashboard,
    },
  },
  {
    path: 'administration/users/list',
    component: () => import('@/views/private/administration/system-users/SystemUsersList.vue'),
    name: 'manageSystemUsers',
    meta: {
      title: t('sidebar.manageSystemUsers'),
      helpUrl: helpUrls.systemUsers,
      breadcrumb: [{ label: t('sidebar.administration') }],
      size: 'md',
    },
  },
  {
    path: 'administration/users/create',
    component: () => import('@/views/private/administration/system-users/create/SystemUserCreate.vue'),
    name: 'registerSystemUser',
    meta: {
      title: t('user.create.title'),
      helpUrl: helpUrls.systemUsers,
      breadcrumb: [
        { label: t('sidebar.administration') },
        { label: t('sidebar.manageSystemUsers'), link: { name: 'manageSystemUsers' } },
      ],
      size: 'sm',
    },
  },
  {
    path: 'administration/users/:id/details',
    component: () => import('@/views/private/administration/system-users/item/SystemUsersItem.vue'),
    name: 'userDetails',
    meta: {
      title: t('sidebar.manageSystemUsers'),
      helpUrl: helpUrls.systemUsers,
      breadcrumb: [
        { label: t('sidebar.administration') },
        { label: t('sidebar.manageSystemUsers'), link: { name: 'manageSystemUsers' } },
      ],
      size: 'sm',
    },
  },
  {
    path: 'administration/companies/list',
    component: () => import('@/views/private/administration/companies/CompaniesList.vue'),
    name: 'manageCompanies',
    meta: {
      title: t('sidebar.manageCompanies'),
      helpUrl: helpUrls.companies,
      breadcrumb: [{ label: t('sidebar.administration') }],
      size: 'lg',
    },
  },
  {
    path: 'administration/companies/:companyNumber',
    component: () => import('@/views/private/administration/companies/item/CompanyItem.vue'),
    name: 'companyDetails',
    meta: {
      title: t('sidebar.manageCompanies'),
      helpUrl: helpUrls.companyDetails,
      breadcrumb: [
        { label: t('sidebar.administration') },
        { label: t('sidebar.manageCompanies'), link: { name: 'manageCompanies' } },
      ],
      size: 'sm',
    },
  },
  {
    path: 'beneficiaries/list',
    component: () => import('@/views/private/beneficiaries/BeneficiariesList.vue'),
    name: 'beneficiariesList',
    meta: {
      title: t('sidebar.beneficiariesList'),
      helpUrl: helpUrls.beneficiary,
      breadcrumb: [{ label: t('sidebar.beneficiaries') }],
    },
  },
  {
    path: 'beneficiaries/:uniqueIdentificationNumber/details',
    component: () => import('@/views/private/beneficiaries/item/BeneficiariesItem.vue'),
    name: 'beneficiary',
    meta: {
      title: t('sidebar.beneficiariesList'),
      helpUrl: helpUrls.beneficiary,
      breadcrumb: [
        { label: t('sidebar.beneficiaries') },
        { label: t('sidebar.beneficiariesList'), link: { name: 'beneficiariesList' } },
      ],
      size: 'sm',
    },
  },
  {
    path: 'beneficiaries/load',
    component: () => import('@/views/private/beneficiaries/load/BeneficiariesLoad.vue'),
    name: 'beneficiaries-load',
    meta: {
      title: t('sidebar.beneficiariesLoad'),
      helpUrl: helpUrls.beneficiaryLoad,
      breadcrumb: [{ label: t('sidebar.beneficiaries') }],
      size: 'sm',
    },
  },
  {
    path: 'beneficiaries/allotment',
    component: () => import('@/views/private/beneficiaries/allotment/AllotmentList.vue'),
    name: 'beneficiaries-allotment',
    meta: {
      title: t('sidebar.beneficiariesAllotment'),
      helpUrl: helpUrls.beneficiaryAllotment,
      breadcrumb: [{ label: t('sidebar.beneficiaries') }],
      size: 'lg',
    },
  },
  {
    path: 'beneficiaries/allotment/:allotmentId/details',
    component: () => import('@/views/private/beneficiaries/allotment/item/AllotmentItem.vue'),
    name: 'allotment',
    meta: {
      title: t('sidebar.beneficiariesAllotmentDetails'),
      helpUrl: helpUrls.beneficiaryAllotment,
      breadcrumb: [
        { label: t('sidebar.beneficiaries') },
        { label: t('sidebar.beneficiariesAllotment'), link: { name: 'beneficiaries-allotment' } },
      ],
      size: 'sm',
    },
  },
  {
    path: 'interventions/selector',
    component: () => import('@/views/private/intervention/selector/SelectorTool.vue'),
    name: 'intervention-selector',
    meta: {
      title: t('sidebar.interventionSelector'),
      helpUrl: helpUrls.interventions,
      breadcrumb: [{ label: t('sidebar.interventions') }],
      size: 'sm',
    },
  },
  {
    path: 'interventions/issue',
    component: () => import('@/views/private/intervention/issue/GroupCardIssue.vue'),
    name: 'intervention-issue',
    meta: {
      title: t('sidebar.groupIssue'),
      helpUrl: helpUrls.interventions,
      breadcrumb: [{ label: t('sidebar.interventions') }],
      size: 'sm',
    },
  },
  {
    path: 'interventions/reissue',
    component: () => import('@/views/private/intervention/reissue/GroupCardReissue.vue'),
    name: 'intervention-reissue',
    meta: {
      title: t('sidebar.groupReissue'),
      helpUrl: helpUrls.interventions,
      breadcrumb: [{ label: t('sidebar.interventions') }],
      size: 'sm',
    },
  },
  {
    path: 'interventions/deissue',
    component: () => import('@/views/private/intervention/deissue/CardDeissue.vue'),
    name: 'intervention-deissue',
    meta: {
      title: t('sidebar.interventionDeissue'),
      helpUrl: helpUrls.interventions,
      breadcrumb: [{ label: t('sidebar.interventions') }],
      size: 'sm',
    },
  },
];
