import { t } from '@/plugins/i18n';

export default [
  {
    path: '/',
    name: 'startPage',
    component: () => import('@/views/public/StartPage.vue'),
    meta: { title: t('navigation.home') },
  },
  {
    name: 'register-account',
    path: '/prijava',
    component: () => import('@/views/public/account/register/RegisterAccount.vue'),
    meta: { title: t('navigation.registration') },
  },
  {
    name: 'validate-account',
    path: '/provera',
    component: () => import('@/views/public/account/validate/ValidateAccount.vue'),
    meta: { title: t('navigation.validateAccount') },
  },
  {
    name: 'register-company',
    path: '/prijava-kompanije',
    component: () => import('@/views/public/company/register/RegisterCompany.vue'),
    meta: { title: t('navigation.registration') },
  },
  {
    name: 'activation',
    path: '/aktivacija',
    component: () => import('@/views/public/password/ActivatePassword.vue'),
  },
  {
    name: 'resetPassword',
    path: '/reset-lozinke',
    component: () => import('@/views/public/password/ResetPassword.vue'),
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/public/login/LoginPage.vue'),
    meta: {
      title: t('login.title'),
    },
  },
  {
    name: 'login-initial',
    path: '/prva-prijava',
    component: () => import('@/views/public/login/InitialLogin.vue'),
    meta: {
      title: t('login.title'),
    },
  },
  {
    name: 'forgotten-password',
    path: '/zaboravljena-lozinka',
    component: () => import('@/views/public/login/ForgottenPassword.vue'),
    meta: {
      title: t('login.forgottenPassword'),
    },
  },
];
