import { createRouter, createWebHistory } from 'vue-router';

import PublicLayout from '@/layouts/PublicLayout.vue';
import PrivateLayout from '@/layouts/PrivateLayout.vue';

import PublicRoutes from './public-routes';
import PrivateRoutes from './private-routes';

import { useAuthStore } from '@/stores/authorization';
import { useCookies } from 'vue3-cookies';

import { navigationGuard } from './navigation-guard';
import { clearState } from '@/constants/auth';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: PublicLayout,
      children: PublicRoutes,
    },
    {
      path: '/',
      component: PrivateLayout,
      children: PrivateRoutes,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      meta: { title: 'Неовлашћен приступ' },
      component: () => import('@/views/UnauthorizedView.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      meta: { title: 'Грешка 404' },
      component: () => import('@/views/NotFound.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} | ПИО` : 'ПИО';

  // authStore() has to be inside beforeEach https://github.com/vuejs/pinia/discussions/971#discussioncomment-2356997
  const { authState, setAuthState } = useAuthStore();
  const { cookies } = useCookies();

  const accessToken = cookies.get('_accessToken');
  const refreshToken = cookies.get('_refreshToken');
  if (authState.isAuth && (!accessToken || !refreshToken)) {
    setAuthState(JSON.parse(JSON.stringify(clearState)));
    return;
  }
  // if route requires auth and user not logged
  if (to.matched.some(record => record.meta.requiresAuth) && !authState.isAuth) {
    // add redirect to url query and go to login page
    next({ name: 'login', query: { redirect: to.fullPath } });
    return;
  }
  if (!to.meta.requiresAuth && authState.isAuth) {
    next({ name: 'dashboard' });
    return;
  }
  // if user dosn't have required claim, redirect to unauthorized page
  // currently if user has one of the claims, access to page will be granted,
  // in case that we need to handle a case where user needs multiple claims to access the page
  // logic here will have to be changed
  const guard = navigationGuard[to.name];
  if (to.meta.requiresAuth && to.meta.claims?.length && !authState.user.claims.some(claim => guard.includes(claim))) {
    // add redirect to url query and go to login page
    next({ name: 'unauthorized' });
    return;
  }
  // if logged in and navigating to home redirect to dashboard
  if (to.path === '/' && authState.isAuth) {
    next({ name: 'dashboard' });
    return;
  }

  // does not require auth
  // or requires auth and is logged in

  next();
});

export default router;
