// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          background: '#EFF5F9',
          primary: '#184a86',
          'primary-light': '#429a86',
          secondary: '#b06f6f',
          success: '#4CAF50',
          warning: '#FB8C00',
          'pio-primary': '#edfaff',
          appbar: '#0f2a4a',
        },
      },
    },
  },
});
export default vuetify;
