<script setup>
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';

const appConfig = inject('appConfig');
const router = useRouter();
const { xs, smAndDown } = useDisplay();

const breadcrumbs = computed(() => {
  return router.currentRoute.value.meta?.breadcrumb || [];
});
const title = computed(() => {
  return router.currentRoute.value.meta?.title || '';
});

const helpUrl = computed(() => {
  const routeUrl = router.currentRoute.value.meta?.helpUrl;
  if (!routeUrl) return '';
  return `${appConfig.env.help_root}/${routeUrl}`;
});
</script>
<template>
  <v-container v-if="breadcrumbs.length" fluid class="pa-0 ma-0">
    <v-row no-gutters>
      <v-col cols="12">
        <template v-for="(crumb, index) in breadcrumbs" :key="index">
          <router-link v-if="crumb.link" :to="crumb.link" class="me-2" style="text-decoration: none">
            <small>{{ crumb.label }} /</small>
          </router-link>
          <small v-else style="opacity: 0.5" class="me-2">{{ crumb.label }} /</small>
        </template>
      </v-col>
      <v-col v-if="!smAndDown" cols="12" class="d-flex align-center justify-space-between">
        <div class="d-flex align-center justify-space-between">
          <portal-target name="pageTitle">
            <strong v-if="xs">{{ title }}</strong>
            <h2 v-else>{{ title }}</h2>
          </portal-target>
          <a v-if="helpUrl" :href="helpUrl" target="_blank" class="ms-2">
            <v-icon icon="mdi-help-circle-outline" color="primary" />
          </a>
        </div>
        <portal-target name="pageStatus" />
      </v-col>
      <template v-else>
        <v-col cols="12" class="d-flex align-center">
          <portal-target name="pageTitle">
            <strong v-if="xs">{{ title }}</strong>
            <h2 v-else>{{ title }}</h2>
          </portal-target>
          <a v-if="helpUrl" :href="helpUrl" target="_blank" class="ms-2">
            <v-icon icon="mdi-help-circle-outline" color="primary" />
          </a>
        </v-col>
        <v-col cols="12">
          <portal-target name="pageStatus" />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
