<script setup>
import { SidebarMenu } from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import { useDisplay } from 'vuetify';
import { useAuthStore } from '@/stores/authorization';
import { useSettingsStore } from '@/stores/settings';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { navigationGuard } from '@/router/navigation-guard';

const { xs } = useDisplay();
const { toggleSidebar } = useSettingsStore();
const { sidebarCollapse, theme } = storeToRefs(useSettingsStore());
const { authState } = storeToRefs(useAuthStore());
const { t } = useI18n();

const menuWidth = 300;

const isVisible = routeName => {
  const routeClaims = [];
  if (typeof routeName === 'string') {
    const guard = navigationGuard[routeName] || [];
    routeClaims.push(...guard);
  }
  if (Array.isArray(routeName)) {
    routeName.forEach(route => {
      const guard = navigationGuard[route];
      if (!guard) {
        console.warn(`Guard definition missing for route ${route}`);
        return;
      }
      guard.forEach(claim => {
        if (routeClaims.includes(claim)) return;
        routeClaims.push(claim);
      });
    });
  }
  if (!routeClaims.length) return false;
  return authState.value.user.claims.some(claim => routeClaims.includes(claim));
};

// const systemUsersMenu = canManageUsers
const systemUsersMenu = isVisible(['administration-users'])
  ? [
      {
        title: t('sidebar.manageSystemUsers'),
        href: { name: 'manageSystemUsers' },
        icon: 'mdi mdi-shield-account',
      },
    ]
  : [];
const companiesMenu = isVisible(['administration-users'])
  ? [
      {
        title: t('sidebar.manageCompanies'),
        href: { name: 'manageCompanies' },
        icon: 'mdi mdi-domain',
      },
    ]
  : [];
const administrationMenu = isVisible(['administration-users'])
  ? [
      {
        title: t('sidebar.administration'),
        icon: 'mdi mdi-cog-outline',
        child: [...systemUsersMenu, ...companiesMenu],
      },
    ]
  : [];

const beneficiariesList = isVisible(['beneficiary'])
  ? [
      {
        title: t('sidebar.beneficiariesList'),
        href: { name: 'beneficiariesList' },
        icon: 'mdi mdi-information',
      },
    ]
  : [];
const beneficiariesLoad = isVisible(['beneficiaries-load'])
  ? [
      {
        title: t('sidebar.beneficiariesLoad'),
        href: { name: 'beneficiaries-load' },
        icon: 'mdi mdi-account-multiple-plus',
      },
    ]
  : [];
const beneficiariesAllotment = isVisible(['beneficiaries-allotment'])
  ? [
      {
        title: t('sidebar.beneficiariesAllotment'),
        href: { name: 'beneficiaries-allotment' },
        icon: 'mdi mdi-account-group',
      },
    ]
  : [];
const beneficiariesMenu = isVisible(['beneficiary', 'beneficiaries-load'])
  ? [
      {
        title: t('sidebar.beneficiaries'),
        icon: 'mdi mdi-account-supervisor',
        child: [...beneficiariesLoad, ...beneficiariesList, ...beneficiariesAllotment],
      },
    ]
  : [];

const interventionIssue = isVisible(['administrator'])
  ? [
      {
        title: t('sidebar.groupIssue'),
        href: { name: 'intervention-issue' },
        icon: 'mdi mdi-account-multiple-check-outline',
      },
    ]
  : [];
const interventionReissue = isVisible(['administrator'])
  ? [
      {
        title: t('sidebar.groupReissue'),
        href: { name: 'intervention-reissue' },
        icon: 'mdi mdi-account-sync-outline',
      },
    ]
  : [];
const interventionSelector = isVisible(['administrator'])
  ? [
      {
        title: t('sidebar.interventionSelector'),
        href: { name: 'intervention-selector' },
        icon: 'mdi mdi-account-multiple-plus-outline',
      },
    ]
  : [];
const interventionCardDeissue = isVisible(['administrator'])
  ? [
      {
        title: t('sidebar.interventionDeissue'),
        href: { name: 'intervention-deissue' },
        icon: 'mdi mdi-credit-card-minus-outline',
      },
    ]
  : [];
const interventionMenu = isVisible(['administrator'])
  ? [
      {
        title: t('sidebar.interventions'),
        icon: 'mdi mdi-wrench-cog-outline',
        child: [...interventionSelector, ...interventionIssue, ...interventionReissue, ...interventionCardDeissue],
      },
    ]
  : [];

const menu = [
  {
    title: t('sidebar.dashboard'),
    href: { name: 'dashboard' },
    icon: 'mdi mdi-view-dashboard',
  },
  ...administrationMenu,
  ...beneficiariesMenu,
  ...interventionMenu,
];
</script>
<template>
  <sidebar-menu
    v-if="!xs || (xs && !sidebarCollapse)"
    :menu="menu"
    :collapsed="xs ? false : sidebarCollapse"
    :theme="theme === 'light' ? 'white-theme' : ''"
    :width="menuWidth + 'px'"
    @update:collapsed="toggleSidebar"
  />
</template>
<style lang="scss">
.v-sidebar-menu.vsm_white-theme {
  background-color: #f2f2f2;
}
.v-sidebar-menu.vsm_white-theme .vsm--link:hover {
  color: lightgray;
  background-color: #184a86;
}
.v-sidebar-menu.vsm_white-theme .vsm--link:hover > i {
  color: lightgray;
  background-color: #184a86;
}
.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link.vsm--link_mobile-item.vsm--link_active {
  color: lightgray;
  background-color: #184a86;
}
.v-sidebar-menu.vsm_white-theme .vsm--toggle-btn {
  border-top: 1px solid lightgray;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
  background-color: transparent;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
  background-color: #184a86;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--link_level-1.vsm--link_open {
  .vsm--icon {
    background-color: #184a86;
  }
  background-color: #184a86;
}
.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link_level-1.vsm--link_open {
  .vsm--icon {
    background-color: #184a86;
  }
  background-color: #184a86;
}
.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link.vsm--link_mobile-item .vsm--icon {
  background-color: #184a86;
}
.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link.vsm--link_mobile-item.vsm--link_active {
  background-color: #184a86;
}
</style>
