export const clearState = {
  isAuth: false,
  user: {
    login: '',
    nameAndSurname: '',
    claims: [],
  },
  expirationDate: null,
  lastActivity: null,
};
