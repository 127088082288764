<script setup>
import { computed } from 'vue';
import { useAuthStore } from '@/stores/authorization';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import { useSettingsStore } from '@/stores/settings';
import { storeToRefs } from 'pinia';
import AppBar from '@/views/private/components/AppBar.vue';
import SideBar from '@/views/private/components/SideBar.vue';
import TheBreadcrumbs from '@/components/TheBreadcrumbs.vue';

const { keepAlive } = storeToRefs(useAuthStore());
const { sidebarCollapse } = storeToRefs(useSettingsStore());
const { xs, thresholds } = useDisplay();
const router = useRouter();
const routeKey = computed(() => {
  const fullPath = router.currentRoute.value.fullPath;
  return fullPath.endsWith('/details') ? fullPath : router.currentRoute.value.path;
});

const cardWidth = computed(() => {
  const routeSize = router.currentRoute.value?.meta?.size;
  if (!routeSize) return '100%';
  const size = thresholds.value[routeSize];
  if (!size) {
    console.warn(`Size '${routeSize}' not defined in vuetify thresholds`);
    return '100%';
  }
  return size;
});
</script>
<template>
  <div class="private" :class="xs ? '' : sidebarCollapse ? 'padding-left-65' : 'padding-left-300'">
    <AppBar />
    <SideBar />
    <router-view v-slot="{ Component }">
      <keep-alive :include="keepAlive">
        <v-container fluid :class="{ 'pa-0': xs, 'ma-0': xs }">
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-center">
              <v-card :width="cardWidth">
                <v-card-title>
                  <TheBreadcrumbs :key="routeKey" />
                </v-card-title>
                <component :is="Component" :key="routeKey" />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </keep-alive>
    </router-view>
  </div>
</template>
