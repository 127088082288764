<script setup>
import { ref, inject, watch } from 'vue';
import { useDisplay } from 'vuetify';
import AlertContainer from '@/components/AlertContainer.vue';
import { useSettingsStore } from '@/stores/settings';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/authorization';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';

const appConfig = inject('appConfig');
const sessionDurationMins = appConfig.env.sessionDurationMins;

const router = useRouter();
const { authState } = storeToRefs(useAuthStore());
const { logout } = useAuthStore();
const { theme, setTheme } = useSettingsStore();
const { autoLogout } = storeToRefs(useSettingsStore());
setTheme(theme);

// watch isAuth can't be in authStore composable because its reinitialized on every page reload,
// which causes watch to trigger and push to /
watch(
  () => authState.value.isAuth,
  newValue => {
    if (!newValue) {
      const currRoute = autoLogout.value
        ? {
            name: 'login',
            query: { redirect: router.currentRoute.value.query?.redirect || router.currentRoute.value.fullPath },
          }
        : '/';
      router.push(currRoute);
      return;
    }
    const redirectRoute = router.currentRoute.value?.query?.redirect;
    if (redirectRoute) {
      router.push(redirectRoute === '/login' ? { name: 'dashboard' } : redirectRoute);
      return;
    }
    if (router.currentRoute.value?.path !== '/login') return;
    router.push({ name: 'dashboard' });
  },
  { deep: true },
);

let logoutTimer = null;
watch(
  () => authState.value.lastActivity,
  newValue => {
    if (!newValue) return;
    if (!authState.value.isAuth) return;
    // handle case where user closes tab and is not active, then reopens tab
    const currentDate = DateTime.fromMillis(Date.now());
    const lastActivityDate = DateTime.fromMillis(newValue);
    const diff = currentDate.diff(lastActivityDate, 'minutes');
    if (diff.toObject().minutes > sessionDurationMins) {
      logout(true);
      clearTimeout(logoutTimer);
      return;
    }

    clearTimeout(logoutTimer);
    if (!newValue) return;
    const duration = sessionDurationMins * 60 * 1000 - 30 * 1000;
    logoutTimer = setTimeout(() => {
      logout(true);
    }, duration);
  },
  { deep: true },
);

const appKey = ref(0);
const { xs } = useDisplay();
watch(xs, () => {
  appKey.value++;
});
</script>

<template>
  <v-app :theme="theme" :class="theme">
    <v-main :key="appKey">
      <router-view />
      <alert-container />
    </v-main>
  </v-app>
</template>
